<template>
  <div class="container">
    <ContentHeader
      v-bind:title="content_header.title"
      v-bind:subTitle="content_header.subTitle"
    />
    <div class="page__body">
      <div class="text-right">
        <button
          class="button page__body__header__button ml-auto button--grey text--capital"
          type="button"
          data-toggle="modal"
          data-target="#exampleModal"
          v-if="userCanCreateTemplates"
        >
          Add Template <i class="ki ki-plus icon-sm ml-3"> </i>
        </button>
      </div>

      <div class="mt-5">
        <table class="table customtable customtable__templateid">
          <thead class="table__header">
            <tr class="table_header_text">
              <th>ID Type</th>
              <th>Issuing Authority</th>
              <th>ID Document Template</th>
              <th>Mandatory For Customers</th>
              <th>Allow Attachments</th>
              <th>Actions</th>
            </tr>
          </thead>
          <tbody>
            <tr
              class="table__text"
              v-for="item in allTemplates"
              v-bind:key="item.identificationDocumentTemplateKey"
            >
              <td>{{ item.documentType }}</td>
              <td>{{ item.issuingAuthority }}</td>
              <td>{{ item.documentIdTemplate }}</td>
              <td>{{ item.mandatoryForClients === true ? "Yes" : "No" }}</td>
              <td>{{ item.allowAttachments === true ? "Yes" : "No" }}</td>
              <td>
                <div class="dropdown">
                  <button
                    class="button btn-light dropdown-toggle"
                    type="button"
                    data-toggle="dropdown"
                    aria-haspopup="true"
                    aria-expanded="true"
                  >
                    Action
                  </button>
                  <div
                    class="dropdown-menu dropdown-menu-container"
                    aria-labelledby="dropdownMenuButton"
                  >
                    <a
                      href="#"
                      class="dropdown-item"
                      type="button"
                      data-toggle="modal"
                      data-target="#editIdTemplateModal"
                      v-on:click="editIdTemplate(item)"
                      v-if="userCanEditTemplates"
                      >Edit</a
                    >
                    <a
                      href="#"
                      class="dropdown-item"
                      type="button"
                      data-toggle="modal"
                      data-target="#deleteConfirmModal"
                      v-on:click="
                        openConfirmation(item.identificationDocumentTemplateKey)
                      "
                      v-if="userCanDeleteTemplates"
                      >Delete</a
                    >
                    <!-- v-on:click="deleteIdTemplate(item.documentIdTemplateKey)" -->
                  </div>
                </div>
              </td>
            </tr>
          </tbody>
        </table>
      </div>
      <div class="pagination">
        <div class="d-flex align-items-center justify-content-between w-100">
          <div class="text--capital text--regular pagination__text">
            row per page:
            <span
              class="d-inline-block text--primary"
              style="padding-right: 15px"
            >
              <select @change="changeLength" v-model="size">
                <option :value="10">10</option>
                <option :value="20">20</option>
                <option :value="30">30</option>
                <option :value="50">50</option>
                <option :value="100">100</option>
              </select>
            </span>
            <span class="d-inline-block text--primary">{{
              !paginations.hasPreviousPage
                ? paginations.pageIndex
                : size * (paginations.pageIndex - 1) + 1
            }}</span>
            -
            <span class="d-inline-block text--primary">{{
              paginations.hasNextPage
                ? size * paginations.pageIndex
                : paginations.totalCount
            }}</span>
            of
            <span>{{ paginations ? paginations.totalCount : 0 }}</span>
          </div>
          <div
            class="pagination__buttons d-flex align-items-center justify-content-between"
            v-if="paginations"
          >
            <button
              class="button button--sm text--regular"
              @click="changePage('previous')"
              :disabled="!paginations.hasPreviousPage"
            >
              <ion-icon name="chevron-back-outline"></ion-icon>
            </button>
            <button
              v-for="page in paginations.totalPages"
              :key="page"
              :class="{
                'text--white button--primary ': paginations.pageIndex === page,
              }"
              class="button text--regular button--sm ml-2"
              @click="changePage(page)"
            >
              {{ page }}
            </button>
            <button
              class="button text--regular button--sm ml-2"
              @click="changePage('next')"
              :disabled="!paginations.hasNextPage"
            >
              <ion-icon name="chevron-forward-outline"></ion-icon>
            </button>
          </div>
        </div>
      </div>
    </div>

    <!-- Modal -->
    <div class="modal modal__right" id="exampleModal" aria-hidden="true">
      <div class="">
        <div class="modal__dialog" role="document">
          <div class="modal__content">
            <div class="modal__header">
              <ion-icon
                class="modal__head__icon modal__close modal__close--black"
                name="arrow-back-outline"
                data-dismiss="modal"
                id="formCloseButton"
                aria-label="Close"
                @click="clearForm"
                :disabled="isSaving"
              ></ion-icon>
              <div class="modal__header__title">
                <h5 class="modal__head__title">Create ID Template</h5>
              </div>

              <!-- <div
                class="alert alert-success alert-dismissible fade show"
                role="alert"
              >
                Id template created successfully.
                <button
                  type="button"
                  class="close"
                  data-dismiss="alert"
                  aria-label="Close"
                >
                  <span aria-hidden="true">&times;</span>
                </button>
              </div> -->
            </div>
            <form class="form" @submit="saveForm">
              <div class="form__item">
                <label class="form__label">ID Type</label>
                <input
                  class="input form__input w-100"
                  v-model="form.documentType"
                  placeholder="Enter ID TYPE"
                  v-bind:class="{ 'input-required': err.documentType }"
                />
              </div>
              <div class="form__item">
                <label class="form__label">Issuing Authrioty</label>
                <input
                  class="input form__input w-100"
                  placeholder="Enter Issuing Authority"
                  v-model="form.issuingAuthority"
                  v-bind:class="{ 'input-required': err.issuingAuthority }"
                />
              </div>
              <div class="form__item">
                <label class="form__label">
                  ID Document Template
                  <i
                    class="bi bi-info-circle-fill"
                    data-toggle="tooltip"
                    data-placement="top"
                    style="font-size: 1.6rem"
                    :title="'The symbol # represent Numbers (Ex. 1,2,3...)\nThe symbol @ represent Alphabets (Ex. ABCabc)\nThe symbol $ represent AlphaNumeric (Ex. ABCabc123)'"
                  ></i>
                </label>
                <input
                  class="input form__input w-100"
                  placeholder="Enter ID Document Template"
                  v-model="form.documentIdTemplate"
                  v-bind:class="{ 'input-required': err.documentIdTemplate }"
                />
              </div>
              <!-- <div class="form__item">
                <input
                  class="form__checkbox mr-4"
                  type="checkbox"
                  v-model="form.mandatoryForClients"
                />
                <label class="form__label">Mandatory for Customers</label>
              </div> -->
              <div class="form__item">
                <label
                  for="mandatoryCustomer"
                  class="has-checkbox page__body__header__label text--capital mb-3 text--black"
                  >Mandatory for Customers
                  <input
                    type="checkbox"
                    id="mandatoryCustomer"
                    class="form- mr-4"
                    v-model="form.mandatoryForClients"
                    :checked="form.mandatoryForClients"
                  />
                  <span class="checkmark"></span>
                </label>
              </div>

              <!-- <div class="form__item">
                <input
                  class="form__checkbox mr-4"
                  type="checkbox"
                  v-model="form.allowAttachments"
                />
                <label class="form__label">Allow Attachments</label>
              </div> -->
              <div class="form__item">
                <label
                  for="allowAttachments"
                  class="has-checkbox page__body__header__label text--capital mb-3 text--black"
                  >Allow Attachments
                  <input
                    type="checkbox"
                    id="allowAttachments"
                    class="form- mr-4"
                    v-model="form.allowAttachments"
                    :checked="form.allowAttachments"
                  />
                  <span class="checkmark"></span>
                </label>
              </div>

              <button
                class="button form__button form__button--lg"
                type="submit"
                v-if="!isSaving"
              >
                Save Changes
              </button>
              <!-- loader button -->
              <a
                role="button"
                class="button form__button form__button--lg w-25 save-changes"
                v-if="isSaving"
                :disabled="true"
              >
                <div class="spinner-border text-light" role="status"></div>
              </a>
              <button
                class="button form__action__text form__action__text--danger ml-5"
                data-dismiss="modal"
                aria-label="Close"
                @click="clearForm"
                :disabled="isSaving"
              >
                Cancel
              </button>
            </form>
          </div>
        </div>
      </div>
    </div>
    <!-- /Modal -->

    <!-- Modal -->
    <div class="modal modal__right" id="editIdTemplateModal" aria-hidden="true">
      <div class="">
        <div class="modal__dialog" role="document">
          <div class="modal__content">
            <div class="modal__header">
              <ion-icon
                class="modal__head__icon modal__close modal__close--black"
                name="arrow-back-outline"
                data-dismiss="modal"
                id="formCloseButton2"
                aria-label="Close"
                @click="clearForm"
                :disabled="isSaving"
              ></ion-icon>
              <div class="modal__header__title">
                <h5 class="modal__head__title">Edit ID Template</h5>
              </div>
            </div>
            <form class="form" @submit="saveEditForm">
              <div class="form__item">
                <label class="form__label">ID Type</label>
                <input
                  class="input form__input w-100"
                  v-model="form.documentType"
                  placeholder="Enter ID TYPE"
                  v-bind:class="{ 'input-required': err.documentType }"
                />
              </div>
              <div class="form__item">
                <label class="form__label">Issuing Authrioty</label>
                <input
                  class="input form__input w-100"
                  placeholder="Enter Issuing Authority"
                  v-model="form.issuingAuthority"
                  v-bind:class="{ 'input-required': err.issuingAuthority }"
                />
              </div>
              <div class="form__item">
                <label class="form__label">
                  ID Document Template
                  <i
                    class="bi bi-info-circle-fill"
                    data-toggle="tooltip"
                    data-placement="top"
                    style="font-size: 1.6rem"
                    :title="'The symbol # represent Numbers (Ex. 1,2,3...)\nThe symbol @ represent Alphabets (Ex. ABCabc)\nThe symbol $ represent AlphaNumeric (Ex. ABCabc123)'"
                  ></i>
                </label>
                <input
                  class="input form__input w-100"
                  placeholder="Enter ID Document Template"
                  v-model="form.documentIdTemplate"
                  v-bind:class="{ 'input-required': err.documentIdTemplate }"
                />
              </div>
              <!-- <div class="form__item">
                <input
                  class="form__checkbox mr-4"
                  type="checkbox"
                  v-model="form.mandatoryForClients"
                />
                <label class="form__label">Mandatory for Customers</label>
              </div>
              <div class="form__item">
                <input
                  class="form__checkbox mr-4"
                  type="checkbox"
                  v-model="form.allowAttachments"
                />
                <label class="form__label">Allow Attachments</label>
              </div> -->
              <div class="form__item">
                <label
                  for="mandatoryCustomer"
                  class="has-checkbox page__body__header__label text--capital mb-3 text--black"
                  >Mandatory for Customers
                  <input
                    type="checkbox"
                    id="mandatoryCustomer"
                    class="form- mr-4"
                    v-model="form.mandatoryForClients"
                    :checked="form.mandatoryForClients"
                  />
                  <span class="checkmark"></span>
                </label>
              </div>

              <div class="form__item">
                <label
                  for="allowAttachments"
                  class="has-checkbox page__body__header__label text--capital mb-3 text--black"
                  >Allow Attachments
                  <input
                    type="checkbox"
                    id="allowAttachments"
                    class="form- mr-4"
                    v-model="form.allowAttachments"
                  />
                  <span class="checkmark"></span>
                </label>
              </div>
              <button
                class="button form__button form__button--lg"
                type="submit"
                v-if="!isSaving"
              >
                Save Changes
              </button>
              <!-- loader button -->
              <a
                role="button"
                class="button form__button form__button--lg w-25 save-changes"
                v-if="isSaving"
                :disabled="true"
              >
                <div class="spinner-border text-light" role="status"></div>
              </a>
              <button
                class="button form__action__text form__action__text--danger ml-5"
                data-dismiss="modal"
                aria-label="Close"
                @click="clearForm"
                :disabled="isSaving"
              >
                Cancel
              </button>
            </form>
          </div>
        </div>
      </div>
    </div>
    <!-- /Modal -->
    <!-- Modal -->
    <div
      class="modal modal__confirm"
      id="deleteConfirmModal"
      aria-hidden="true"
    >
      <div class="">
        <div class="modal__dialog" role="document">
          <div class="modal__head">
            <div
              class="modal__close modal__head__icon modal__close--white"
              data-dismiss="modal"
              aria-label="Close"
            >
              <a
                ><span>
                  <ion-icon name="close-outline"></ion-icon> Close</span
                ></a
              >
            </div>

            <h5 class="modal__header__title">Confirmation</h5>
          </div>
          <div class="modal__content">
            <div class="modal__header">
              <div class="modal__header__title">
                <h5 class="modal__head__title">
                  Are you sure you want to delete this ID Template ?
                </h5>
              </div>
              <div class="modal__header__subtitle">
                <h6 class="my-4">
                  When you delete this ID Template from your list , you won't be
                  able to access it again,
                </h6>
              </div>
            </div>

            <div>
              <button
                class="button form__button form__button--lg mr-5 modal__confirm__button modal__confirm__button--cancel"
                data-dismiss="modal"
                aria-label="Close"
                id="closeButton"
              >
                Cancel
              </button>
              <!-- loader button -->
              <a
                role="button"
                class="button form__button form__button--lg w-25 modal__confirm__button modal__confirm__button--delete"
                v-if="isDeleting"
                :disabled="true"
              >
                <div class="spinner-border text-light" role="status"></div>
              </a>
              <button
                class="button form__button form__button--lg modal__confirm__button modal__confirm__button--delete"
                type="submit"
                v-if="!isDeleting"
                v-on:click="deleteIdTemplate(deleteId)"
              >
                Delete
              </button>
            </div>
          </div>
        </div>
      </div>
    </div>
    <!-- /Modal -->
    <!--ALERT NOTIFICATION-->
    <div class="toaster show">
      <div
        class="toaster__container"
        v-if="alert.show"
        v-bind:class="{
          'toaster--success': alert.status === 'success',
          'toaster--error': alert.status === 'fail',
        }"
      >
        <div class="text-right">
          <span class="fa fa-times" @click="alert.show = false"></span>
        </div>
        <div>
          <span class="d-block text--small text--600 text--white">{{
            alert.title
          }}</span>
          <span class="d-block text--tiny text--400 text--white">{{
            alert.description
          }}</span>
        </div>
      </div>
    </div>
  </div>
</template>
<script>
import Vue from "vue";
import { Role } from "@/core/services/role.js";
import { mapState } from "vuex";
import idTemplatesService from "@/core/services/general-setup-service/id-templates.service";
export default Vue.extend({
  name: "id-templates",
  data: function () {
    return {
      content_header: {
        title: "ID Templates",
        subTitle: "",
        // subTitle: "Set up organization's allowable ID Templates.",
      },
      idTemplate: null,
      allTemplates: [],
      isLoading: false,
      isSaving: false,
      isDeleting: false,
      alert: {
        show: false,
        status: "Success",
        title: "Success",
        description: "",
      },
      deleteId: null,
      form: {
        documentIdTemplate: null,
        documentType: null,
        issuingAuthority: null,
        mandatoryForClients: false,
        allowAttachments: false,
      },
      err: {
        documentIdTemplate: false,
        documentType: false,
        issuingAuthority: false,
      },
      paginations: {},
      pages: 1,
      size: 20,
    };
  },
  methods: {
    async getIdTemplates() {
      try {
        this.isLoading = true;
        const res = await idTemplatesService.get({
          pageSize: this.size,
          pageIndex: this.pages,
        });
        this.allTemplates = res.data.data.items;
        this.paginations = res.data.data;
        this.isLoading = false;
      } catch (e) {
        const error = e.response.data.message;
        if (error) return this.alertError(error);
      }
    },
    changePage(type) {
      if (type === "next") {
        this.pages++;
      } else if (type === "previous") {
        this.pages--;
      } else {
        this.pages = type;
      }

      this.getIdTemplates();
    },

    changeLength(e) {
      const { value } = e.target;
      this.size = parseInt(value);
      this.pages = 1;
      this.getIdTemplates();
    },
    editIdTemplate(idTemplate) {
      try {
        this.isLoading = true;
        this.idTemplate = idTemplate;
        this.form = {
          documentIdTemplate: idTemplate.documentIdTemplate,
          documentType: idTemplate.documentType,
          issuingAuthority: idTemplate.issuingAuthority,
          mandatoryForClients: idTemplate.mandatoryForClients,
          allowAttachments: idTemplate.allowAttachments,
        };
        this.form.identificationDocumentTemplateKey =
          idTemplate.identificationDocumentTemplateKey;
        this.isLoading = false;
      } catch (e) {
        this.isLoading = false;
        const error = e.response.data.message;
        this.alertError(error);
      }
    },
    saveForm: async function (e) {
      e.preventDefault();
      if (this.validateForm()) {
        try {
          this.isSaving = true;
          await idTemplatesService.create(this.form);
          this.getIdTemplates();
          this.clearForm();
          document.getElementById("formCloseButton").click();
          this.alertSuccess("New Id templated added.");
          this.isSaving = false;
        } catch (e) {
          this.isSaving = false;
          const error = e.response.data.message;
          this.alertError(error);
        }
      }
    },

    saveEditForm: async function (e) {
      e.preventDefault();
      if (this.validateForm()) {
        try {
          this.isSaving = true;
          await idTemplatesService.update(
            this.form.identificationDocumentTemplateKey,
            this.form
          );
          this.getIdTemplates();
          this.clearForm();
          document.getElementById("formCloseButton2").click();
          this.alertSuccess("Id template updated.");
          this.isSaving = false;
        } catch (e) {
          this.isSaving = false;
          const error = e.response.data.message;
          this.alertError(error);
        }
      }
    },
    validateForm() {
      this.clearValidation();
      if (
        this.form.documentIdTemplate &&
        this.form.documentType &&
        this.form.issuingAuthority
      ) {
        return true;
      }

      if (!this.form.documentIdTemplate) {
        this.err.documentIdTemplate = true;
      }
      if (!this.form.documentType) {
        this.err.documentType = true;
      }
      if (!this.form.issuingAuthority) {
        this.err.issuingAuthority = true;
      }

      return false;
    },
    clearValidation() {
      this.err = {
        documentIdTemplate: false,
        documentType: false,
        issuingAuthority: false,
      };
    },
    clearForm: function () {
      this.form = {
        documentIdTemplate: null,
        documentType: null,
        issuingAuthority: null,
        mandatoryForClients: false,
        allowAttachments: false,
      };
    },
    async deleteIdTemplate(id) {
      this.isDeleting = true;
      try {
        await idTemplatesService.delete(id);
        document.getElementById("closeButton").click();
        this.isDeleting = false;
        this.alertSuccess("Id template deleted");
        this.getIdTemplates();
      } catch (e) {
        this.isDeleting = false;
        const error = e.response.data.message;
        this.alertError(error);
      }
    },
    openConfirmation(id) {
      this.deleteId = id;
    },
    alertSuccess(description) {
      this.alert.status = "success";
      this.alert.title = "Success";
      this.alert.description = description;
      this.alert.show = true;
      const self = this;
      setTimeout(function () {
        self.alert.show = false;
      }, 15000);
    },
    alertError(description) {
      this.alert.status = "fail";
      this.alert.title = "Error";
      this.alert.description = description;
      this.alert.show = true;
      const self = this;
      setTimeout(function () {
        self.alert.show = false;
      }, 15000);
    },
  },
  async created() {
    this.getIdTemplates();
  },
  computed: {
    ...mapState({
      user: (state) => state.auth.user,
    }),
    userCanCreateTemplates() {
      const templates = this.user.scopes.find(
        (item) => item.scopeName === Role.GeneralSetup
      );
      const { permissionsList } = templates;
      return permissionsList.CanCreateIDTemplate;
    },
    userCanEditTemplates() {
      const templates = this.user.scopes.find(
        (item) => item.scopeName === Role.GeneralSetup
      );
      const { permissionsList } = templates;
      return permissionsList.CanEditIDTemplate;
    },
    userCanDeleteTemplates() {
      const templates = this.user.scopes.find(
        (item) => item.scopeName === Role.GeneralSetup
      );
      const { permissionsList } = templates;
      return permissionsList.CanDeleteIDTemplate;
    },
  },
});
</script>
